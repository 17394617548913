<template>
  <div class="pt-20">
    <div
      class="md:w-1024 mx-auto pt-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
    >
      <div
        class="w-full text-left text-textBlack flex flex-col md:flex-row items-start justify-between"
      >
        <!-- <video class="h-44" rel="prefetch" autoplay loop muted playsinline>
          <source
            src="~@/assets/videos/Partnervideosmallersize.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video> -->
        <img
          class="h-72"
          src="@/assets/images/icons/neoCertification.jpeg"
          alt="neoCertification"
        />
        <div class="pl-4">
          <h1 class="mb-4 text-4xl font-semibold text-primary">
            Inside nexgen
          </h1>
          <p class="w-full text-base text-textBlack text-justify">
            nexgen is a Silicon Valley company based in San Jose, California
            with offices and channel partners around the world. nexgen is
            dedicated to providing English language learners with effective
            learning content, which when combined with real-time advanced Al,
            allows learners to achieve their English goals—guaranteed. Backed by
            over 30 years of big data with the same course materials, neo will
            automatically individualize the content for each learner to ensure
            mastery of key concepts.
          </p>
        </div>
      </div>
    </div>
    <div
      class="md:w-1024 mx-auto pt-4 pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
    >
      <div
        class="w-full text-left text-textBlack"
      >
        <div>
          <h5 class="text-xl text-primary font-semibold">More about nexgen:</h5>
          <div class="pt-1">
            End User License Agreement (EULA) -
            <a
              class="text-secondary underline"
              href="https://nexgenenglishonline.co/nexgeneula"
              >English</a
            >,
            <a
              class="text-secondary underline"
              href="https://nexgenenglishonline.co/nexgeneulacn"
              >Chinese</a
            >,
            <a
              class="text-secondary underline"
              href="https://nexgenenglishonline.co/nexgeneulath"
              >Thai</a
            >,
            <a
              class="text-secondary underline"
              href="https://nexgenenglishonline.co/nexgeneulasp"
              >Spanish</a
            >,
            <a
              class="text-secondary underline"
              href="https://nexgenenglishonline.co/nexgeneulaid"
              >Indonesia</a
            >
          </div>
        </div>
        <div>
          <div>
            Privacy Policy -
            <a
              class="text-secondary underline"
              href="https://nexgenenglishonline.co/privacypolicy"
              >English</a
            >,
            <a
              class="text-secondary underline"
              href="https://nexgenenglishonline.co/privacypolicycn"
              >Chinese</a
            >
          </div>
        </div>
        <div>
          <div>
            Myneo Privacy Policy -
            <a
              class="text-secondary underline"
              href="https://nexgenenglishonline.co/myneoprivacypolicy/webview"
              >English</a
            >,
            <a
              class="text-secondary underline"
              href="https://nexgenenglishonline.co/myneoprivacypolicycn/webview"
              >Chinese</a
            >
          </div>
        </div>
        <div class="pt-2">
          <h5>Chat with us on Facebook and Instagram or reach us at:</h5>
          <ul>
            <li class="flex items-center">
              <svg width="16" height="16" fill="#49C5FE" viewBox="0 0 24 24">
                <path
                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"
                />
              </svg>
              <a
                href="https://www.facebook.com/Nexgen-neo-1773897936237827/"
                target="_blank"
                class="pl-1"
                style="margin: 0 0 0 5px; transition: all 0.2s"
                >Facebook</a
              >
            </li>
            <li class="flex items-center">
              <svg width="16" height="16" fill="#49C5FE" viewBox="0 0 24 24">
                <path
                  d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                />
              </svg>
              <a
                href="https://www.instagram.com/nexgen.neo/"
                target="_blank"
                class="pl-1"
                style="margin: 0 0 0 5px; transition: all 0.2s"
                >Instagram</a
              >
            </li>
            <li class="flex items-center">
              <svg width="16" height="16" fill="#49C5FE" viewBox="0 0 24 24">
                <path
                  d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"
                />
              </svg>
              <a
                href="mailto:support@nexgenenglishonline.co"
                class="pl-1"
                style="margin: 0 0 0 5px; transition: all 0.2s"
                >support@nexgenenglishonline.co</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>